import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CoreService } from "src/app/modules/core/services/core.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";
import { IPaginatedData } from "src/app/modules/core/interfaces/pagination/pagination.interface";
import { ENDPOINTS as COMMONENDPOINTS } from "src/app/modules/admin/shared/endpoint-common";
import { ENDPOINTS as APPCOREENDPOINT } from "src/app/modules/core/services/endpoints";
import { environment } from "src/environments/environment";
import { IPermissionGroup } from "src/app/modules/core/interfaces/permission/permission-group.interface";
import { IPermissions } from "src/app/modules/core/interfaces/permission/permissions.interface";
import { IList } from "../interfaces/user.interface";

@Injectable()
export class UserService {

  configurationServiceUrl: string;
  aggregationServiceUrl: string;
  miscellaneousServiceUrl: string;
  cachedAllUsers$: Observable<any[]>;

  constructor(
    private readonly http: HttpClient,
    public coreService: CoreService
  ) {
    this.configurationServiceUrl = coreService.getConfigurationServiceUrl();
    this.aggregationServiceUrl = coreService.getAgregationServiceUrl();
    this.miscellaneousServiceUrl = coreService.getMiscellaneousServiceUrl();
  }
  getAllUsers(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.miscellaneousServiceUrl}/getAllUsers`).pipe(shareReplay());
  }

  getAllRoles(): Observable<any[]> {
    return this.http
      .get<any[]>(APPCOREENDPOINT.role_analytics.url);
  }

  getAllScopes(): Observable<any[]> {
    return this.http
      .get<any[]>(APPCOREENDPOINT.scope.url);
  }

  getAllPermissionGroups(): Observable<IPermissionGroup[]> {
    return this.http
      .get<IPermissionGroup[]>(COMMONENDPOINTS.permission_group_list.url);
  }

  getPortfolioSummary(): Observable<Partial<any[]>> {
    return this.http
      .get<Partial<any[]>>(`${this.configurationServiceUrl}/use-case/summary`);
  }
  getPortfolioBase(): Observable<Partial<any[]>> {
    return this.http
      .get<any[]>(COMMONENDPOINTS.usecase_base_list.url);
  }

  getAllCityProfilesBase(): Observable<any[]> {
    return this.http
      .get<any[]>(COMMONENDPOINTS.city_profile_base_list.url);
  }

  getAllCityProfiles(): Observable<IList[]> {
    return this.http
      .get<IList[]>(`${this.configurationServiceUrl}/city-profile/summary`);
  }

  getAllPermissionList(): Observable<Partial<IPermissions[]>> {
    return this.http
      .get<Partial<IPermissions[]>>(COMMONENDPOINTS.permission_list.url);
  }

  getPermissionGroupById(id: any): Observable<IPermissionGroup> {
    if (environment.mock) {
      return this.http
        .get<IPermissionGroup>(COMMONENDPOINTS.permission_group_by_id.url);
    }
    return this.http
      .get<IPermissionGroup>(`${this.configurationServiceUrl}/permission-group/${id}`);
  }

  getAllUserSummaryForWorkflow(pageParams): Observable<any> {
    if (!this.cachedAllUsers$) {
      this.cachedAllUsers$ = this.requestAllUserSummaryForWorkflow(pageParams).pipe(
        shareReplay(1)
      );
    }
    return this.cachedAllUsers$;
  }

  requestAllUserSummaryForWorkflow(pageParams): Observable<any> {
    const { page, size } = pageParams;
    let params = new HttpParams().append("page", page).append("size", size);
    if (pageParams.searchText) {
      params = params.append("searchText", pageParams.searchText);
    }
    return this.http.get<IPaginatedData>(
      APPCOREENDPOINT.user_list.url, { params }
    );
  }


  getAllUserSummary(pageParams): Observable<any> {
    const { page, size } = pageParams;
    let params = new HttpParams().append("page", page).append("size", size);
    if (pageParams.searchText) {
      params = params.append("searchText", pageParams.searchText);
    }
    return this.http.get<IPaginatedData>(
      APPCOREENDPOINT.user_list.url, { params }
    );
  }

  getUserById(id: number) {
    if (environment.mock) {
      return this.http
        .get<any[]>(APPCOREENDPOINT.user_by_id.url);
    }
    return this.http
      .get<any[]>(`${this.configurationServiceUrl}/user-role?userId=${id}`);
  }

  getImageById(id: number) {
    // expo-api/configuration/generic/image/24
    return this.http
      .get<any[]>(`${this.configurationServiceUrl}/generic/image/${id}`);
  }

  createUser(userDetails) {
    return this.http.post(
      `${this.configurationServiceUrl}/user-role`,
      userDetails
    );
  }

  createRole(roleDetail) {
    return this.http.post<Partial<any>>(
      `${this.configurationServiceUrl}/role/portfolio/hierarchy`,
      roleDetail
    );
  }
  updatePermissionGroup(formToBeSubmitted: Partial<IPermissionGroup>) {
    return this.http.put<Partial<any>>(
      `${this.configurationServiceUrl}/permission-group/${formToBeSubmitted.id}`,
      formToBeSubmitted
    );
  }

  createPermissionGroup(formToBeSubmitted: Partial<IPermissionGroup>) {
    return this.http.post(
      `${this.configurationServiceUrl}/permission-group`,
      formToBeSubmitted
    );
  }


  getRoleDetailById(id: number | string): any {
    const params = new HttpParams();
    params.set("withCityProfile", "yes");
    if (environment.mock) {
      return this.http
        .get<any>(APPCOREENDPOINT.role_by_id.url, { params });
    }
    return this.http
      .get<any>(`${this.configurationServiceUrl}/role/portfolio/hierarchy?roleId=${id}`, { params });
  }

  updateRole(roleDetail) {
    return this.http.put<Partial<any>>(
      `${this.configurationServiceUrl}/role/portfolio/hierarchy?roleId=${roleDetail.roleId}`,
      roleDetail
    );
  }

  updateUser(userDetails) {
    return this.http.put(
      `${this.configurationServiceUrl}/user-role?userId=${userDetails.userDto.id}`,
      userDetails
    );
  }

  deleteUser(id: number) {
    return this.http
      .delete(`${this.configurationServiceUrl}/user-role?userId=${id}`);
  }

  deleteRole(id: number) {
    return this.http
      .delete(`${this.configurationServiceUrl}/role/portfolio/hierarchy?roleId=${id}`);
  }

  deletePermission(id: number) {
    return this.http
      .delete(`${this.configurationServiceUrl}/permission-group/${id}`);
  }
  getUserByEmailId(email: string) {
    return this.http
      .get<any[]>(`${this.configurationServiceUrl}/user/logged-in/${email}`);
  }
}
