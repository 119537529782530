import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  isPortfolioVisible$ = new BehaviorSubject(false);
  isElementUpdated = new BehaviorSubject(false);
  activePortfolioIndex$ = new BehaviorSubject(0);
  isHierarchyLoading$ = new BehaviorSubject(false);
  showLoader$ = new BehaviorSubject({value: false, isFirstChange: true});
  cachedHierarchy: any = {};
  cachedElementType: any = [];
  constructor() { }
}
