import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./modules/core/guards/auth-guard.service";
import { UserResolve } from "./modules/core/resolvers/user.resolve";
import { NavbarComponent } from "./modules/shared/components/navbar/navbar.component";
import { LandingComponent } from "./pages/landing/landing.component";
import { LogoutPageComponent } from "./pages/logout/logout.page";
import { UnauthorizedComponent } from "./pages/unauthorized/unauthorized.component";

const routes: Routes = [
	{ path: 'landing', component: LandingComponent },
	{ path: 'logout', component: LogoutPageComponent },
	{ path: 'unauthorized', component: UnauthorizedComponent },
	// { path: 'main', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
	{
		path: 'user',
		component: NavbarComponent,
		loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
		resolve: {
			user: UserResolve
		},
		canActivate: [AuthGuardService],
		data: {
			auth: 'USER',
			type: 'categoty'
		}
	},
	{
		path: 'admin',
		component: NavbarComponent,
		loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
		resolve: {
			user: UserResolve
		},
		canActivate: [AuthGuardService],
		data: {
			auth: ['ADMIN', 'SUPER_ADMIN'],
			type: 'categoty'
		}
	},
	{
		path: '**',
		// resolve: {
		// 	user: UserResolve
		// },
		redirectTo: 'user'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "reload" })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
