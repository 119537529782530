import { Injectable } from "@angular/core";

@Injectable()
export class DynamicLoaderService {
	templateMapper: {};
	constructor() {}

	setComponent(componentMapper) {
		this.templateMapper = componentMapper;
	}

	getComponent(component) {
		return this.templateMapper[component];
	}

}
