<!-- <div class="h-100 d-flex flex-column has-navbar-fixed-top"> -->
<si-side-panel #sidePanel size="wide" [collapsed]="collapsed" [mode]="'over'" (closed)="closeSwitcher()">
	<si-navbar [appName]="appName" [showSecondaryNavbar]="isSecondaryNavbarAvailable"
		[currentAppPermission]="'app.pm'" [defaultHelpPortalURL]="'APP_CDP_URLS.DEFAULT'"
		[infoLinks]="{ items: [{ title: 'HORIZON_UI.ABOUT_PAGE.TITLE', link: '/about' }]}" [lang]="currentLanguage"
		(logout)="onLogoutClick()" [showCreateClient]="false" [enableOfflinePage]="true"
		[accountManagerUrl]="myProfile" (paramsData)="getParams($event)" data-cy="navbar-component"
		(themeChanged)="onthemeChanged($event)" [allowAccountsAppNavigationOnApply]="false"
		[appSwitcherDataSource]="appSwitcherURL" (langChanged)="onlangChanged($event)" [showLangSwitcher]="false"
		[showPartitionsContainer]="false" [menuItems]="menuItems" (customerChange)="onCustomerChange($event)" (customerSelection)="onCustomerSelection($event)"
		>
		<!-- 
				[collapsed]="true" [subMenu]="items"
				[languages]="availableLanguages"
		  		[persistCustomerId]="persistCustomerId"
		  		[openCustomerSwitcher]="true"
			  	[allowPartitionFilter]="true"
			  	(isSwitcherOpened)="isSwitcherOpened($event)"
			  	(customerSelection)="customerSelection($event)"
			  	[menuItems]="accountItems"
	  	 	-->
		<ng-content></ng-content>
		<div>
			<!--  [ngClass]="isCustomerSwitcherOpened ? 'disable-ui' : ''" -->
			<router-outlet></router-outlet>
		</div>
	</si-navbar>
</si-side-panel>
<!-- </div> -->