import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ModalModule } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ChangeRoleViewComponent } from "./components/change-role-view/change-role-view.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { HeaderComponent } from "./components/header/header.component";
import { ModalComponent } from "./components/modal/modal.component";
import { RadioSwitchComponent } from "./components/radio-switch/radio-switch.component";

import { FilterPipe } from "./pipes/filter.pipe";
import { PadStartPipe } from "./pipes/pad-start.pipe";
import { ReversePipe } from "./pipes/reverse.pipe";
import { TranslatePipe } from "./pipes/translate.pipe";
import { DateTimePipe } from "./pipes/date-time.pipe";

import { MaskDirDirective } from "./directives/mask-dir.directive";
import { EllipsisTooltipDirective } from "./directives/ellipsis-tooltip.directive";
import { NumberFormatterPipe } from './pipes/number-formatter.pipe';
import { ChangeLocaleComponent } from './components/change-locale/change-locale.component';
import { SiDatatableModule, SiDropdownModule, SiEmptyStateModule, SiFormModule, SiIconModule, SiLoadingSpinnerModule, SiMenuLegacyModule, SiPaginationModule, SiSearchBarModule, SiSelectModule, SiTabsModule, SiTreeViewModule, SI_DATATABLE_CONFIG, SiFileUploaderModule, SiCardModule, SiBreadcrumbModule, SiNavbarModule, SiSidePanelModule, SiSelectSimpleOptionsDirective, SiSelectSingleValueDirective, SiSelectMultiValueDirective, SiPopoverModule, SiAccordionModule, SiWizardModule, SiDateRangeFilterModule, SiTooltipModule } from "@simpl/element-ng";
import { NgxDatatableModule } from "@siemens/ngx-datatable";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ImageCustomPipe as ImagePipe } from "./pipes/image.pipe";
import { SourcePipe } from "./pipes/source.pipe";
import { ValidationMessagesComponent } from './components/validation-messages/validation-messages.component';
import { NavbarComponent } from "./components/navbar/navbar.component";
import {
  SiAODSModule,
  SiUOModule,
  SiSubscriptionsModule,
  SiAODSService,
  SiSharedModule
} from '@building-x/common-ui-ng';
import { OAuthModule } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { RouterModule } from "@angular/router";
import { ElementsSelectorComponent } from "./components/elements-selector/elements-selector.component";
import { MonthFilterComponent } from "./components/month-filter/month-filter.component";
import { CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { SubMultiSelectComponent } from "./components/sub-multi-select/sub-multi-select.component";
import { NameFilterPipe } from "./pipes/name-filter.pipe";
import { StringifyMetricListPipe } from "./pipes/stringify-metric-list.pipe";
import { WorkflowInstancesComponent } from "./components/workflow-instances/workflow-instances.component";
import { WorkflowStagesComponent } from "./components/workflow-stages/workflow-stages.component";
import { WorkflowActionHistoryComponent } from "./components/workflow-action-history/workflow-action-history.component";
import { WorkflowSendReminderComponent } from "./components/workflow-send-reminder/workflow-send-reminder.component";
import { WorkflowPopupComponent } from "./components/workflow-popup/workflow-popup.component";
import { HighlightPipe } from "./pipes/highlight.pipe";
import { ViewWorkflowComponent } from "./components/view-workflow/view-workflow.component";
import { WorkflowDisplayComponent } from "./components/workflow-display/workflow-display.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { DynamicLoaderService } from "./components/loader/dynamic-loader.service";
import { InitialsPipe } from "./pipes/initials.pipe";
import { UserWorkflowStagesPreviewComponent } from "./components/user-workflow-stages-preview/user-workflow-stages-preview.component";

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ChangeRoleViewComponent,
    ConfirmDialogComponent,
    RadioSwitchComponent,
    HeaderComponent,
    ModalComponent,
    RadioSwitchComponent,
    FilterPipe,
    PadStartPipe,
    ReversePipe,
    TranslatePipe,
    DateTimePipe,
    MaskDirDirective,
    EllipsisTooltipDirective,
    NumberFormatterPipe,
    ImagePipe,
    InitialsPipe,
    ChangeLocaleComponent,
    SourcePipe,
    ValidationMessagesComponent,
    NavbarComponent,
    SubMultiSelectComponent,
    ElementsSelectorComponent,
    MonthFilterComponent,
    NameFilterPipe,
    StringifyMetricListPipe,
    WorkflowInstancesComponent,
    WorkflowStagesComponent,
    WorkflowActionHistoryComponent,
    WorkflowSendReminderComponent,
    WorkflowPopupComponent,
    HighlightPipe,
    ViewWorkflowComponent,
    WorkflowDisplayComponent,
    LoaderComponent,
    UserWorkflowStagesPreviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    TimepickerModule.forRoot(),
    SiSearchBarModule,
    NgxDatatableModule.forRoot(SI_DATATABLE_CONFIG),
    SiDatatableModule,
    SiPaginationModule,
    SiLoadingSpinnerModule,
    SiEmptyStateModule,
    SiFormModule,
    SiDropdownModule,
    SiMenuLegacyModule,
    SiFileUploaderModule,
    SiIconModule,
    SiSelectModule,
    SiTabsModule,
    SiTreeViewModule,
    SiCardModule,
    SiAccordionModule,
    SiBreadcrumbModule,
    SiWizardModule,
    BsDatepickerModule.forRoot(),
    OAuthModule.forRoot(),
    SiUOModule.forRoot({
      apiUrl: environment.apiUrlV2,
      product: environment.product,
      noGoogleApi: environment.test,
    }),
    SiSubscriptionsModule.forRoot({
      apiUrl: environment.subscriptionUrl,
      //apiUrlV2: environment.subscriptionUrl,
      product: environment.product,
      noGoogleApi: environment.test,
    }),
    SiAODSModule.forRoot({
      apiUrl: `${environment.aodsUrl}`,
    }),
    SiNavbarModule,
    SiSharedModule,
    RouterModule,
    SiSidePanelModule,
    CdkOverlayOrigin, CdkConnectedOverlay,
    SiSelectSimpleOptionsDirective,
    SiSelectSingleValueDirective,
    SiSelectMultiValueDirective,
    SiPopoverModule,
    SiAccordionModule,
    SiWizardModule,
    SiDateRangeFilterModule,
    OverlayModule,
    SiTooltipModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FilterPipe,
    PadStartPipe,
    ReversePipe,
    TranslatePipe,
    DateTimePipe,
    NumberFormatterPipe,
    ImagePipe,
    InitialsPipe,
    MaskDirDirective,
    NgxUiLoaderModule,
    PopoverModule,
    ModalComponent,
    BreadcrumbComponent,
    HeaderComponent,
    RadioSwitchComponent,
    ModalModule,
    ToastrModule,
    TimepickerModule,
    AccordionModule,
    PaginationModule,
    TooltipModule,
    EllipsisTooltipDirective,
    SiSearchBarModule,
    NgxDatatableModule,
    SiDatatableModule,
    SiPaginationModule,
    SiLoadingSpinnerModule,
    SiEmptyStateModule,
    SiFormModule,
    SiDropdownModule,
    SiMenuLegacyModule,
    SiFileUploaderModule,
    SiIconModule,
    SiSelectModule,
    SiSelectSimpleOptionsDirective,
    SiSelectSingleValueDirective,
    SiSelectMultiValueDirective,
    SiTabsModule,
    SiTreeViewModule,
    SiCardModule,
    SiBreadcrumbModule,
    BsDatepickerModule,
    SourcePipe,
    NameFilterPipe,
    ValidationMessagesComponent,
    ElementsSelectorComponent,
    MonthFilterComponent,
    SubMultiSelectComponent,
    StringifyMetricListPipe,
    WorkflowInstancesComponent,
    WorkflowStagesComponent,
    WorkflowActionHistoryComponent,
    WorkflowSendReminderComponent,
    WorkflowPopupComponent,
    HighlightPipe,
    ViewWorkflowComponent,
    WorkflowDisplayComponent,
    LoaderComponent,
    UserWorkflowStagesPreviewComponent,
    SiWizardModule
  ],
  providers: [
    SiAODSModule,
    SiAODSService,
    DynamicLoaderService
  ]
})
export class SharedModule {
}
