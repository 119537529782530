import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { ENDPOINTS } from "./endpoints";
import { IThreshold } from "src/app/modules/core/interfaces/metric/metric.threshold.interface";
import { IThresholdInterval, IVisualizationType } from "src/app/modules/core/interfaces/metric/metric.interface";
import { IAppConfig } from "../interfaces/app-config/app-config-interface";
import { ISwitchType } from "../interfaces/switch-type/ISwitchType";
import { CoreService } from "./core.service";

@Injectable()
export class AppConfigService {
	private thresholdList: IThreshold[];
	private thresholdIntervalList: IThresholdInterval[];
	private sortOrder: string[];
	private aggregationTypes: string[];
	private visualizationTypes: IVisualizationType[];
	private appConfig: IAppConfig[];
	switchTypes: ISwitchType[];

	constructor(private readonly _http: HttpClient, private coreService: CoreService) { }

	private getThresholdListDetails() {
		return this._http.get<IThreshold[]>(ENDPOINTS.threshold_list.url)
			.pipe(map((thresholdList: IThreshold[]) => {
				this.thresholdList = thresholdList;
				return thresholdList;
			}));
	}

	private getAggregationTypes() {
		return this._http.get<string[]>(ENDPOINTS.aggregation_types.url)
			.pipe(map((aggregationTypes: string[]) => {
				this.aggregationTypes = aggregationTypes;
				return aggregationTypes;
			}));
	}

	private getSortTypes() {
		return this._http.get<string[]>(ENDPOINTS.sort_types.url)
			.pipe(map((sortTypes: string[]) => {
				this.sortOrder = sortTypes;
				return sortTypes;
			}));
	}

	private getThresholdIntervals() {
		return this._http.get<IThresholdInterval[]>(ENDPOINTS.threshold_interval.url)
			.pipe(map((intervals: IThresholdInterval[]) => {
				this.thresholdIntervalList = intervals;
				return intervals;
			}));
	}

	private getVisualizationTypes() {
		return this._http.get<IVisualizationType[]>(ENDPOINTS.visualization_types.url)
			.pipe(tap((data: IVisualizationType[]) => {
				this.visualizationTypes = data;
			}));
	}

	private getAppConfig() {
		return this._http.get<IAppConfig[]>(ENDPOINTS.app_config.data)
			.pipe(tap((data) => {
				this.appConfig = data;
			}));
	}

	private getSwitchTypes() {
		return this._http.get<ISwitchType[]>(ENDPOINTS.switch_types.url)
			.pipe(
				tap(res => this.switchTypes = res),
			);
	}

	getThresholdList() {
		return this.thresholdList;
	}

	getThresholdIntervalList() {
		return this.thresholdIntervalList;
	}

	getThresholdIntervalById(id: number) {
		const interval = this.thresholdIntervalList.find((intervalData: IThresholdInterval) => intervalData.id === id);
		return interval ? interval : null;
	}

	getThresholdById(id: number) {
		const threshold = this.thresholdList.find((thresholdData: IThreshold) => thresholdData.id === id);
		return threshold ? threshold : null;
	}

	getSortOrder() {
		return this.sortOrder;
	}

	getAggregationTypesList() {
		return this.aggregationTypes;
	}

	getVisualizationTypeList() {
		return this.visualizationTypes;
	}

	getAppConfigList() {
		return this.appConfig;
	}

	thresholdListInitializer(): Promise<any> {
		return this.getThresholdListDetails()
			.toPromise();
	}

	getAggregationTypesInitializer(): Promise<any> {
		return this.getAggregationTypes()
			.toPromise();
	}

	getSortTypesInitializer(): Promise<any> {
		return this.getSortTypes()
			.toPromise();
	}

	getThresholdIntervalsInitializer(): Promise<any> {
		return this.getThresholdIntervals()
			.toPromise();
	}

	getSwitchTypesInitializer() {
		return this.getSwitchTypes().toPromise();
	}

	getVisualizationInitializer(): Promise<Array<IVisualizationType>> {
		return this.getVisualizationTypes().toPromise();
	}

	getAppConfigInitializer(): Promise<any> {
		return this.getAppConfig().toPromise();
	}

	getAppConfigByKey(key: string) {
		return this.appConfig.find((config: IAppConfig) => config.key === key);
	}

	getSwitchTypeById(id: number) {
		return this.switchTypes.find(el => el.id === id);
	}

  getConfigurationServiceURL() {
    return this.coreService.getConfigurationServiceUrl();
  }

}
