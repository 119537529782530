import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NavbarItem, SiModalService, SiSidePanelService } from "@simpl/element-ng";
import {
	SiCookiesService,
	AppSidePanelStateService,
	SiNavbarComponent,
} from "@building-x/common-ui-ng";
import { environment } from "src/environments/environment";
import { SettingsService } from "src/app/services/settings.service";
import { config } from "src/assets/app-config";
import { Localization } from "src/app/services/localization/localization.service";
import { ChangeRoleViewComponent } from "../change-role-view/change-role-view.component";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { TenantService } from "src/app/modules/admin/tenant-management/services/tenant.service";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit {
	currentLanguage = "";
	appSwitcherURL = environment.appSwitcherSource;
	public myProfile: any = config.myprofileURl;
	isSecondaryNavbarAvailable = false;
	collapsed = true;
	destroy$ = new Subject();
	appName = 'Portfolio Manager';
	@ViewChild(SiNavbarComponent) siNavbar!: SiNavbarComponent;
	storedTheme;
	menuItems: NavbarItem[] = [
		{ title: 'Change Role', icon: 'element-activate-user', action: () => this.changeRole() }
	];

	constructor(
		private readonly cookieService: SiCookiesService,
		private readonly router: Router,
		public localizationService: Localization,
		private readonly appSidePanelStateService: AppSidePanelStateService,
		private readonly sidePanelService: SiSidePanelService,
		private settingsService: SettingsService,
		private readonly modalService: SiModalService,
		private readonly authService: AuthService,
		private readonly tenantService: TenantService
	) {
		this.appName = environment.appName;
	}

	ngOnInit(): void {
		this.storedTheme = this.settingsService.theme;
		const savedLanguage = this.cookieService.getCookie("lang");
		if (savedLanguage) {
			this.currentLanguage = savedLanguage;
			this.localizationService.setUserLanguage(savedLanguage);
		}

		const userDetails = this.authService.getUser();

		if (userDetails) {
			let isSuperAdmin = false;
			userDetails.listOfRoles.forEach(role => {
				if (role.listOfScopes.find((scope) => scope.code == "SAD")) {
					isSuperAdmin = true;
				}
			});

			if (isSuperAdmin) {
				const tenantList = [];
				this.tenantService.getAllTENANTs({}).pipe(takeUntil(this.destroy$)).subscribe((tenantDetials) => {
					tenantDetials.forEach(tenant => {
						tenantList.push({
							title: tenant.name,
							action: () => this.setActiveTenant(tenant, true)
						})
					});
					this.setActiveTenant(tenantDetials[0], true);
				}, (error) => {
					console.log("Error", error);
				});

				this.menuItems.splice(0, 0, {
					title: 'Tenant',
					icon: 'element-user',
					items: tenantList
				});
			}
		}
	}

	ngAfterViewInit(): void {
		this.siNavbar.setTheme(this.storedTheme);
	}

	onLogoutClick() {
		this.router.navigate(["/logout"]);
	}

	onlangChanged(ev: any) {
	}
	onthemeChanged(ev: any) {
		//window.location.reload();
		this.settingsService.setThemeThroughNavbar(ev);
	}

	// Read the params during sso when set in navbar like langauge, redirect urls
	getParams(params: any) {
		params.forEach((param: any) => {
			if (param.keyId === "lang") {
				this.localizationService.setUserLanguage(param.value);
			}
			if (param.keyId === "redirectTo") {
				this.router.navigateByUrl(param.value);
			}
		});
	}

	closeSwitcher() {
		this.siNavbar.openCustomerSwitcher = false; // This is a workaround to open the customer switcher. HUNG team has been notified about this, they will be looking into it
		// this.hungNavbar.closeOfSwitcher(); commented out since closeOfSwitcher is not available in SiNavbarComponent
		this.sidePanelService.setSidePanelContent(undefined);
		this.appSidePanelStateService.isSidePaneCollapsed.next(true);
	}

	changeRole() {
		if (!this.authService.isRoleChangePopUpActive) {
			this.authService.isRoleChangePopUpActive = true;
			const initialStateData = {
				recievedFrom: "header"
			};
			this.modalService.show(ChangeRoleViewComponent, {
				initialState: initialStateData,
				animated: true,
				class: 'modal-lg modal-dialog-centered',
				ignoreBackdropClick: false
			});
		}
	}

	setActiveTenant(tenant: any, updateScreen: boolean) {
		this.menuItems.find((item) => item.title == 'Tenant')?.items.forEach(item => {
			if (item.title == tenant.name) {
				item.selectionState = 'check';
			} else {
				item.selectionState = '';
			}
		});

		this.authService.setActiveTenant(tenant);
		if (updateScreen) {
			this.reloadCurrentRoute();
		}
	}

	reloadCurrentRoute() {
		setTimeout(() => {
			const currentUrl = this.router.url;
			this.router.navigateByUrl(currentUrl.includes("admin") ? "/admin" : "/user", { skipLocationChange: true }).then(() => {
				this.destroy$.next(true);
				this.destroy$.complete();
				this.router.navigate([currentUrl]).then(() => {
					this.destroy$.next(true);
					this.destroy$.complete();
				})
				setTimeout(() => {
					this.destroy$.next(true);
					this.destroy$.complete();
					document.getElementById("common-error-div")?.remove();
				}, 1000);
			});
		}, 1000);
	}

	onCustomerChange(event: any) {
		// this.authService.setActiveTenant(event);
		// this.reloadCurrentRoute();

		console.log("onCustomerChange event::",event);
	}

	onCustomerSelection(event: any) {	
		console.log("onCustomerSelection event::",event);
	}

}
